import './projectHomeDisplay.css';
import './projectHomeDisplayMobile.css';
import React, {useEffect, useState, useRef} from "react";
import ProjectCard from "./projectCard/ProjectCard";
import DesignCard from "./designCard/DesignCard";

const ProjectHomeDisplay = React.memo((props) => {
  let {arr, isMobile, scrollContainer, displayType, setOpen, setDialogContent, setDialogStyle} = props;
  const [isVisible, setIsVisible] = useState([]);
  const arrRefs = useRef([]);
  const [scrollRef, setScrollRef] = useState(null)

  useEffect(() => {
    setIsVisible(Array(arr.length).fill(false));
    arrRefs.current = arrRefs.current.slice(0, arr.length);
  }, [arr.length]);

  useEffect(() => {
    let container = scrollContainer.current;
    let previousScrollY = container.scrollY;

    const handleScroll = () => {
      const currentScrollY = container.scrollY;
      const isScrollingUp = previousScrollY > currentScrollY;
      previousScrollY = currentScrollY;

      const updatedIsVisible = [...isVisible];
      arrRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom >= 0) {
            updatedIsVisible[index] = true;
          } else if (isScrollingUp && updatedIsVisible[index]) {
            updatedIsVisible[index] = false;
          }
        }
      });
      setIsVisible(updatedIsVisible);
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll(); // Check visibility on initial load
    }

    return () => container.removeEventListener('scroll', handleScroll);
  }, [scrollContainer]);

  useEffect(() => {
    if (scrollRef !== null) {
      arrRefs.current[scrollRef].scrollIntoView({behavior: 'smooth'})
    }
  }, [scrollRef]);

  const getRandomRotation = (min, max, alternate, index) => {
    const rotation = Math.floor(Math.random() * (max - min + 1)) + min;
    if (alternate) {
      return index % 2 === 0 ? -rotation : rotation;
    } else {
      return rotation
    }
  }

  return (
    <div className={displayType === 'project' ? 'allProjectDisplayContainer' : `mediaCarouselGrid ${displayType}`}
         style={{gap: displayType === 'homeGrid' ? '0px' : ``}}
    >
      {arr.map((item, index) => {
          return (
            displayType === 'project' ?
              <ProjectCard
                key={`${item.title}-card-${index}`}
                project={item}
                isVisible={isVisible}
                index={index}
                projectRefs={arrRefs}
                setScrollRef={setScrollRef}
                getRandomRotation={getRandomRotation}
              /> :
              <DesignCard
                isMobile={isMobile}
                setOpen={setOpen}
                setDialogContent={setDialogContent}
                setDialogStyle={setDialogStyle}
                isVisible={isVisible}
                key={`${item.title}-card-${index}`}
                item={item}
                index={index}
                arrRefs={arrRefs}
                getRandomRotation={getRandomRotation}
                displayType={displayType}
              />
          )
        }
      )}
    </div>
  )
})

export default ProjectHomeDisplay;