import React, {forwardRef, useEffect} from 'react';
import './circle.css';
import './circleMobile.css';
import $ from 'jquery';

import anime from "animejs";

const Circle = forwardRef(({...props}, ref) => {
  let {key, title, company, desc, side, start, end, last, isInCenter, expand = false, expanded, setExpanded} = props;

  function detectMob() {
    return window.innerWidth <= 640;
  }

  useEffect(() => {
      if (!expand) {
          openCircle(isInCenter)
      }
  }, [isInCenter]);

  useEffect(() => {
    $('.circle').each(function () {
      $(this).css('background-color', "#" + (Math.random() * 16777215 | 0).toString(16))
    })
  }, [])

  const openCircle = (open) => {
    if (last) {
      anime({
        targets: `.skillsTitle`,
        marginTop: open ? [50, detectMob() ? 125 : 75] : [detectMob() ? 125 : 75, 50],
        easing: 'easeInOutQuad',
        duration: 250
      });
    }

    anime({
      targets: `.${company.replaceAll(" ", "")}CircleText`,
      marginTop: open ? [0, detectMob() ? -150 : -150] : [detectMob() ? -150 : -150, 0],
      easing: 'easeInOutQuad',
      duration: 250
    });
    anime({
      targets: `.${company.replaceAll(" ", "")}`,
      display: 'block',
      // marginTop: [-110, 10],
      opacity: open ? ['0%', '100%'] : ['100%', '0%'],
      easing: 'easeInOutQuad',
      duration: 250
    });
  }

  return (
    <div key={key} className={`${side}CircleContainer`}>
      <div
        ref={ref}
        className={`circle ${expand ? 'expandCircle' : ''} noSelect`}
        onMouseEnter={() => {
            if (!expand) {
                openCircle(true)
            }
        }}
        onMouseLeave={() => {
            if (!expand) {
                openCircle(false)
            }
        }}
        onClick={() => {
            if (expand) {
                setExpanded(!expanded)
            }
        }}
      ></div>
      <div className={`${side}CircleText ${company.replaceAll(" ", "")}CircleText`}>
        <div className='circleTitle'>{title}</div>
        <div className='circleCompany'><i>{company}</i></div>
        <div className={`circleDesc ${company.replaceAll(" ", "")}`}>
          {desc}
          <div className='timeRange'>
            <i>{start} - {end}</i>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Circle