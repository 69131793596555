import React, {useState} from "react";

const DesignCard = React.memo((props) => {
  let {isVisible, item, arrRefs, index, getRandomRotation, setDialogContent, setOpen, setDialogStyle, isMobile, displayType} = props;
  const [rotation, setRotation] = useState(0)

  if (rotation === 0) {
    setRotation(getRandomRotation(displayType === 'homeGrid' ? 0 : -10, displayType === 'homeGrid' ? 20 : 10, displayType === 'homeGrid', index));
  }

  function openImage(src, alt, link, type) {
    setOpen(true)
    setDialogContent([
      <img
        className={'dialogImg'}
        src={src}
        alt={alt}
        onClick={() => {
          if (link !== '') {
            window.open(link)
          }
        }}
      />])
    if (isMobile) {
      setDialogStyle({width: "85%", padding: "0"});
    } else {
      if (type === 'poster' || type === 'album') {
        setDialogStyle({height: "calc(100vh - 10rem)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
      } else if (type === 'medium') {
        setDialogStyle({height: "calc(100vh - 20rem)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
      } else if (type === 'small') {
        setDialogStyle({height: "calc(100vh - 35rem)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
      } else if (type === 'long') {
        setDialogStyle({height: "calc(100vh - 60rem)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
      } else {
        setDialogStyle({width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
      }
    }
  }

  return (
    <div
      ref={(el) => (arrRefs.current[index] = el)}
      className={`gridItem ${item[4]}Item`}
      style={{
        transform: displayType === 'homeGrid' ? `rotate(${rotation}deg)` : ``,
        opacity: isVisible[index] ? 1 : 0,
        scale: isVisible[index] ? `1` : `0`,
        boxShadow: displayType === 'homeGrid' ? `0px 2px 5px rgba(0, 0, 0, 0.5)` : ``,
        backgroundColor: displayType === 'homeGrid' ? `#2e2e2e` : ``,
        padding: displayType === `homeGrid` ? `10px` : ``
      }}
    >
      <img
        loading={"lazy"}
        onClick={() => {
          openImage(item[0],
            item[2],
            item[1],
            item[4])
        }}
        style={{
          borderRadius: displayType === 'mediaLogoGrid' ? `0` : '10px',
          width: displayType === 'homeGrid' && isMobile ? `65vw` : displayType === 'homeGrid' && !isMobile ? `20vw` : ``,
      }}
        className={`mediaImg ${item[4]}Img`}
        src={item.length > 2 ? item[3] !== null ? item[3] : item[0] : item[0]}
        alt={item[1]}/>
    </div>
  )
})

export default DesignCard;