import React, {useEffect, useRef, useState} from 'react';
import './navbar.css';
import './navbarMobile.css';

import {useLocation, useNavigate} from "react-router-dom";
import {LinkedIn, Logo, Mail} from "../../img";
import useWindowDimensions from "../../functions/getWindowDimensions";
import NavbarBubble from "../../components/navbarBubble/NavbarBubble";
import TextTransition, {presets} from "react-text-transition";

const Navbar = (props) => {
  let { setOpen, setDialogContent, setDialogStyle, title } = props;
  const logoImgRef = useRef(null)

  const navigate = useNavigate();
  const {height, width} = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  const [useHeader, setUseHeader] = useState(false)

  const [navBubbleOpen, setNavBubbleOpen] = useState(false);

  useEffect(() => {
    setIsMobile(width <= 1024);
    setUseHeader(width <= 1400);
  }, [width]);

  return (
    <div className='navbar noSelect'>
      <div className='navbarContent'>
        <div className='navbarContainer'>
          <div className='logoDiv'>
            <img
              ref={logoImgRef}
              onClick={(e) => {
                const {type, pointerType, pointerId, touches} = e
                if (type === 'touchstart' && touches) {
                  console.log('This is a touchstart.')
                } else if (type === 'click' && pointerId === 1) {
                  navigate('/')
                } else {
                  setNavBubbleOpen(!navBubbleOpen);
                }
              }}
              alt='Logo for Oliver Fox'
              className='logo noSelect'
              src={Logo}
              onMouseEnter={() => setNavBubbleOpen(true)}
            />
            <NavbarBubble logoImgRef={logoImgRef} navBubbleOpen={navBubbleOpen} setNavBubbleOpen={setNavBubbleOpen} isMobile={isMobile}/>
          </div>

          <TextTransition className={'navbarTitle'} translateValue={'50%'} direction={'down'} springConfig={presets.stiff}>{title}</TextTransition>

          <div className='socialLinks'>
            <img onClick={() => window.open('https://www.linkedin.com/in/oliver-fox/')} alt='LinkedIn'
                 className='socialLink linkedIn' src={LinkedIn}/>
            <img onClick={() => {
              if (!isMobile) {
                setDialogStyle({width: '50%', height: 'auto', backgroundColor: '#262626'})
              } else {
                setDialogStyle({backgroundColor: '#262626'})
              }

              setOpen(true)
              setDialogContent([<div>I am available for commission, if you would like to contact me and
                discuss collaboration or further detail of my previous work, please contact: <a
                  style={{pointerEvents: 'auto'}} href={'mailto:hello@ofox.co.uk'} rel={"noreferrer"}
                  target={'_blank'}>hello@ofox.co.uk</a></div>])
            }} alt='Email' className='socialLink mail' src={Mail}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar