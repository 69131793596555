import React from "react";

const OpenImage = (props) => {
  let { key = '', classes = '', isMobile, setOpen, setDialogContent, img, alt, setStyle } = props

  return (
    <img loading={'lazy'} key={key !== '' ? key : ''} onClick={() => {
      setOpen(true)
      setDialogContent([<img className={`openScreenshot`} src={img} alt={alt}/>])
      if (isMobile) {
        setStyle({width: "85%", padding: "0"});
      } else {
        setStyle({width: "auto", height: "auto"});
      }
    }} className={`${classes} screenshot`} style={{width: !isMobile && classes === '' ? '15vw' : ''}} src={img} alt={alt}/>
  )
}

export default OpenImage;