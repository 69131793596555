import React, {useContext, useEffect, useReducer, useState} from 'react';
import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot, CarouselContext} from 'pure-react-carousel';

// Stylesheets
import './projects.css';
import './projectsMobile.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

// Images
import {Arrow} from "../../img";

// Components
import {ProjectSlide, resetLogo} from "../../components";
import {animatePageLoad} from "../../functions/animatePageLoad";

const Projects = (props) => {
  let { home = '', isMobile, projects, title, setTitle } = props;

  useEffect(() => {
    if (!home) {
      setTitle(title)  // Set title for navbar
      document.title = `Oliver Fox - ${title}`;
    }
    resetLogo();
    animatePageLoad(['.projectSlide']);
  }, [title])

  return (
    <div key={`projectContainer${title}`} className={`projectContainer${home} noSelect`}>
      <CarouselProvider
        infinite={true}
        isPlaying={home}
        interval={title === 'Projects' ? 3500 : 5000}
        className={`carousel${home}`}
        orientation={isMobile && !home ? 'vertical' : 'horizontal'}
        naturalSlideWidth={100}
        naturalSlideHeight={1000}
        totalSlides={projects.length}>

        <Slider moveThreshold={0.025}>
          {projects.map((project, index) =>
            <Slide index={index} key={`slide${project.title}`}>
              <div key={`projectSlideContainer${index}`} className={`projectSlide slide${index + 1} projectSlide${home}`}>
                <ProjectSlide
                  home={home}
                  newKey={`project${index}`}
                  title={project.title}
                  titleSize={project.titleSize}
                  desc={project.intro}
                  img={project.img}
                  nav={`/${title.toLowerCase()}/${project.urlNav}`}/>
              </div>
            </Slide>
          )}
        </Slider>
        <div className={`dotContainer dotContainer${!home}`}>
          {projects.map((item, index) => <Dot className='noSelect' key={`dot${index}`} slide={index}/>)}
        </div>
        {isMobile ?
          <div></div>
          :
          <>
            <ButtonBack>
              <img className='projectSlideArrow arrow arrowBack' src={Arrow} alt={'Back arrow'} />
            </ButtonBack>
            <ButtonNext>
              <img className='projectSlideArrow arrow arrowNext' src={Arrow} alt={'Next arrow'} />
            </ButtonNext>
          </>
        }
      </CarouselProvider>
    </div>
  )
}

export default Projects